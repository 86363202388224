import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image";

import Description from "../../Description"
import LineSeparator from "../../LineSeparator"

const graphicProps = {
  style: { overflow: "visible" },
}

const DescriptionSection = ({
  children,
  image,
  styles,
  rules,
  theme,
  swapColumns,
  ...props
}) => (
  <>
    <div className={styles.section}>
      <div className={styles.textColumn}>
        <Description {...props}>{children}</Description>
      </div>

      <div className={styles.imageColumn}>
        <GatsbyImage
          {...{
            ...graphicProps,
            ...image,
            className: styles.image,
          }} />
      </div>
    </div>
    <LineSeparator customStyle={rules.separator} />
  </>
)

DescriptionSection.propTypes = {
  styles: PropTypes.shape({
    section: PropTypes.string.isRequired,
    textColumn: PropTypes.string.isRequired,
    imageColumn: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.node.isRequired,
  swapColumns: PropTypes.bool,
  image: PropTypes.shape({}).isRequired,
}

DescriptionSection.defaultProps = {
  swaptColumns: false,
}

export default DescriptionSection
