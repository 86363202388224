import PropTypes from "prop-types"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Obfuscate from "react-obfuscate"

import { Heading4 } from "../../Heading"

const Profile = ({ name, img, role, email, styles, rules }) => (
  <div className={styles.profile}>
    <GatsbyImage
      image={img.childImageSharp.gatsbyImageData}
      alt={`${name} foto`}
    />

    <div className={styles.info}>
      <Heading4 customStyle={rules.title}>{name}</Heading4>
      <span className={styles.role}>{role}</span>
      <Obfuscate email={email} />
    </div>
  </div>
)

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.shape({}),
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    profile: PropTypes.string.isRequired,
  }).isRequired,
}

export default Profile
