import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import { graphql, useStaticQuery } from "gatsby"

import { Heading3 } from "../../Heading"
import Section from "../../Section"

import Patron from "../Patron"
import { anchorItems } from "../../../constants"

const patronsPhotos = graphql`{
  neckar: file(relativePath: {eq: "team/neckar/o.png"}) {
    childImageSharp {
      gatsbyImageData(width: 120, height: 120, layout: FIXED)
    }
  }
  bubnik: file(relativePath: {eq: "team/bubnik/o.png"}) {
    childImageSharp {
      gatsbyImageData(width: 120, height: 120, layout: FIXED)
    }
  }
}
`

const Patrons = ({ styles, rules }) => {
  const photos = useStaticQuery(patronsPhotos)
  return (
    <Section>
      <FormattedMessage id="team.patrons.title">
        {title => <Heading3 customStyle={rules.title} id={anchorItems.patrons}>{title}</Heading3>}
      </FormattedMessage>
      <div className={styles.patrons}>
        <Patron name="Václav Neckář" img={photos.neckar} />
        <span className={styles.joiner}>&amp;</span>
        <Patron name="Gustav Bubník" img={photos.bubnik} />
      </div>
    </Section>
  )
}

Patrons.propTypes = {
  styles: PropTypes.shape({
    patrons: PropTypes.string.isRequired,
  }).isRequired,
}

export default Patrons
