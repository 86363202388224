import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
/* import LazyLoad from "react-lazyload" */

import { anchorItems } from "../../constants"

import { Heading2 } from "../Heading"
import Section from "../Section"
import Profile from "./Profile"
import Collaborators from "./Collaborators"
import Patrons from "./Patrons"

const coreMembersPhotos = graphql`{
  lucka: file(relativePath: {eq: "team/lucka/foto.png"}) {
    childImageSharp {
      gatsbyImageData(width: 380, layout: CONSTRAINED)
    }
  }
  ondra: file(relativePath: {eq: "team/ondra/foto.png"}) {
    childImageSharp {
      gatsbyImageData(width: 380, layout: CONSTRAINED)
    }
  }
  zuzka: file(relativePath: {eq: "team/zuzka/foto.png"}) {
    childImageSharp {
      gatsbyImageData(width: 380, layout: CONSTRAINED)
    }
  }
}
`

const Team = ({ styles, rules }) => {
  const photos = useStaticQuery(coreMembersPhotos)
  const intl = useIntl()

  return (
    <Section fullWidth>
      <Heading2 id={anchorItems.team} customStyle={rules.title}>
        {intl.formatMessage({ id: "team.title" })}
      </Heading2>

      {/* <LazyLoad height={200}> */}
      <div className={styles.profiles}>
        {["lucka", "ondra", "zuzka"].map(id => (
          <Profile
            key={id}
            img={photos[id]}
            name={intl.formatMessage({ id: `team.${id}.name` })}
            role={intl.formatMessage({ id: `team.${id}.role` })}
            email={intl.formatMessage({ id: `team.${id}.email` })}
          />
        ))}
      </div>
      <Collaborators />
      <Patrons />
      {/* </LazyLoad> */}
    </Section>
  )
}

Team.propTypes = {
  styles: PropTypes.shape({
    profiles: PropTypes.string.isRequired,
  }).isRequired,
}

export default Team
