import { connect } from "react-fela"
import Collaborators from "./Collaborators"
import {
  title,
  collaborators,
  collaborator,
  icon,
  nameAndRole,
  role,
  separator,
} from "./Collaborators.styles"

export default connect({
  title,
  collaborators,
  collaborator,
  icon,
  nameAndRole,
  role,
  separator,
})(Collaborators)
