import PropTypes from "prop-types"
import React from "react"
import { Heading3 } from "../../Heading"
import { FormattedMessage } from "react-intl"

import LineSeparator from "../../LineSeparator"
import Section from "../../Section"
import Icon from "../../Icon"

import { sectionExtend } from "./Collaborators.styles"

const Collaborators = ({ styles, rules }) => (
  <Section extend={sectionExtend}>
    <FormattedMessage id="team.collaborators.title">
      {title => <Heading3 customStyle={rules.title}>{title}</Heading3>}
    </FormattedMessage>
    <div className={styles.collaborators}>
      {[
        { name: "Matyáš Gazur", role: "programming", icon: "programming" },
        { name: "Lukáš Horák", role: "programming", icon: "programming" },
        { name: "Michal Vlasák", role: "programming", icon: "programming" },
        { name: "Pavel Čermák", role: "programming", icon: "programming" },
        { name: "Jan Pechan", role: "programming", icon: "programming" },

        { name: "Gustav Bubník", role: "dabingCz", icon: "dabing" },
        { name: "Maroš Kramár", role: "dabingSk", icon: "dabing" },

        { name: "Veronika Koníčková", role: "photos", icon: "photos" },
        { name: "Michal Láznička", role: "words", icon: "words" },
        { name: "Jan Volejníček", role: "voice", icon: "voice" },
        { name: "Štěpán Prokop", role: "design", icon: "design" },
        { name: "DesignDev", role: "webdesign", icon: "webdesign" },
      ].map(({ name, role, icon }) => (
        <div key={name} className={styles.collaborator}>
          <Icon type={icon} style={rules.icon} />

          <div className={styles.nameAndRole}>
            {name}
            <br />
            <FormattedMessage id={`team.collaborators.${role}`}>
              {role => <span className={styles.role}>{role}</span>}
            </FormattedMessage>
          </div>
        </div>
      ))}
    </div>

    <LineSeparator customStyle={rules.separator} />
  </Section>
)

Collaborators.propTypes = {
  styles: PropTypes.shape({
    collaborators: PropTypes.string.isRequired,
  }).isRequired,
}

export default Collaborators
