export const title = () => ({
  marginBottom: "2.9rem",
})

export const patrons = () => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",

  smMax: {
    flexDirection: "column",
    alignItems: "center",
  },
})

export const joiner = ({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 80,
  margin: "0 2.5rem",

  fontSize: "1.67rem",
  color: "#d4d2da",

  smMin: {
    height: 120,
  },
})
