import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-react-intl"

import BasicIntro from "../BasicIntro"
import Button from "../Button"

import { buttonExtend } from "./Intro.styles"
import { donationUrl } from "../../config"
import { useAppUrl } from "../../hooks/useAppUrl"

const Intro = ({ rules, styles }) => {
  const registrationUrl = useAppUrl("registrace")

  return (
    <BasicIntro intlId="" extend={{ intro: rules.intro }}>
      <div className={styles.buttons}>
        <Button
          pink
          extend={buttonExtend}
          href={donationUrl}
          target="_blank"
          type={Button.Types.LINK}
        >
          <FormattedMessage id="donation.button.support" />
        </Button>
        <Button
          extend={buttonExtend}
          href={registrationUrl}
          target="_blank"
          type={Button.Types.LINK}
          rel="noreferrer"
        >
          <FormattedMessage id="menu.purchase" />
        </Button>
      </div>
    </BasicIntro>
  )
}
Intro.propTypes = {
  styles: PropTypes.shape({
    intro: PropTypes.string.isRequired,
    buttons: PropTypes.string.isRequired,
  }).isRequired,
}

export default Intro
