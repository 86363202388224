export const title = () => ({
  marginTop: "3.8rem",
  marginBottom: "2.6rem",
})

export const profiles = () => ({
  display: "flex",
  justifyContent: "space-between",

  smMax: {
    flexDirection: "column",
  },
})
