import React from "react"

import { anchorItems } from "../constants"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Intro from "../components/Intro"
import About from "../components/About"
import Team from "../components/Team"
import Partners from "../components/Partners"
import CtaRow from "../components/CtaRow"
import Contact from "../components/Contact"

const IndexPage = () => {
  return (
    <Layout headerIntro={<Intro />}>
      <Seo />

      <About />

      <Team />
      <Partners />

      <CtaRow
        titleId="feedback.title"
        buttonLabelId="feedback.button.label"
        link={{ href: `#${anchorItems.contact}` }}
      />
      <Contact />
    </Layout>
  )
}
export default IndexPage
