export const intro = () => ({
  marginTop: "2.5rem",
  marginBottom: "2.25rem",

  smMin: {
    marginTop: "5rem",
    marginBottom: "4.5rem",
  },

  mdMin: {
    marginTop: "10rem",
    marginBottom: "9rem",
  },
})

export const buttons = () => ({
  textAlign: "center",
})

export const buttonExtend = () => ({
  button: {
    width: 205,
    display: "inline-flex",
    justifyContent: "center",
    margin: "0 0.83rem",

    smMax: {
      marginBottom: "0.5rem",
    },
  },
})
