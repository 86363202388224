export const sectionExtend = () => ({
  section: {
    marginTop: "4rem",
  },
})

export const title = () => ({
  marginBottom: "4rem",
})

export const collaborators = () => ({
  marginTop: "2.2rem",
  marginBottom: "2rem",

  textAlign: "center",
})

export const collaborator = () => ({
  display: "inline-flex",
  alignItems: "center",

  width: 240,
  marginBottom: "2.7rem",
})

const iconSize = 40

export const icon = ({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  width: iconSize,
  height: iconSize,
  marginRight: "1rem",

  borderRadius: iconSize / 2,
  backgroundColor: theme.colors.blueLight3,

  "& svg": {
    height: 15,
  },
})

export const nameAndRole = ({ theme }) => ({
  fontWeight: theme.font.weightBold,
  lineHeight: "1.3rem",
  textAlign: "left",
})

export const role = ({ theme }) => ({
  fontWeight: "normal",
  fontSize: "0.9rem",

  color: theme.colors.grey2,
})

export const separator = () => ({
  marginTop: "1rem",
  marginBottom: "1rem",
})
