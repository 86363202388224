export const profile = () => ({
  maxWidth: 380,
  flexBasis: "100%",

  position: "relative",

  mdMax: {
    marginBottom: "1rem",
  },

  mdMin: {
    flexBasis: "33%",
  },

  "& .gatsby-image-wrapper": {
    height: 464,
  },
})

export const info = ({ theme }) => ({
  paddingTop: "1.6rem",
  paddingBottom: "2rem",

  position: "absolute",
  bottom: 16,
  left: 24,
  right: 24,

  borderRadius: 6,
  backgroundColor: theme.colors.white,
  textAlign: "center",
})

export const title = () => ({
  marginBottom: "0.3rem",
})

export const role = ({ theme }) => ({
  display: "block",
  color: theme.colors.grey2,
})
