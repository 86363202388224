import PropTypes from "prop-types"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading4 } from "../../Heading"

const Patron = ({ name, img, styles, rules }) => (
  <div className={styles.patron}>
    <GatsbyImage image={img.childImageSharp.gatsbyImageData} alt="name" />
    <Heading4>{name}</Heading4>
  </div>
)

Patron.propTypes = {
  styles: PropTypes.shape({
    patron: PropTypes.string.isRequired,
  }).isRequired,
}

export default Patron
