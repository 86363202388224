import { combineRules } from "fela"

export const section = () => ({
  display: "flex",
  textAlign: "left",

  smMax: {
    flexDirection: "column",
  },
})

export const separator = () => ({})

const leftColumn = {
  order: 1,
  paddingRight: "1.5rem",
}

const rightColumn = {
  order: 2,
  paddingLeft: "1.5rem",
}

const column = () => ({
  display: "flex",
  flexDirection: "column",

  width: "100%",

  smMin: {
    width: "50%",
  },
})

export const textColumn = combineRules(column, ({ swapColumns = false }) => ({
  ...(swapColumns ? rightColumn : leftColumn),

  alignItems: "flex-start",
  justifyContent: "center",
}))

export const imageColumn = combineRules(column, ({ swapColumns = false }) => ({
  ...(swapColumns ? leftColumn : rightColumn),

  alignItems: swapColumns ? "flex-end" : "flex-start",
  justifyContent: "center",

  smMax: {
    display: "none",
  },
}))

export const image = ({ swapColumns = false }) => ({
  flexShrink: 0,
})
