export const title = () => ({
  marginTop: "2.8rem",
})

export const subTitle = () => ({
  marginTop: "2rem",
})

export const partners = () => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  textAlign: "center",
})

export const partner = () => ({
  display: "flex",
  alignItems: "center",

  margin: "1rem",

  "& img": {
    marginBottom: 0,
  },
})
