import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-react-intl"
/* import LazyLoad from "react-lazyload" */

import { anchorItems } from "../../constants"

import { Heading2 } from "../Heading"
import Section from "../Section"

/* import Note from "./Note" */
import DescriptionSection from "./DescriptionSection"
import Benefits from "./Benefits"
import Citations from "../Citations"

import { aboutSectionExtend } from "./About.styles"

import { strongTagReplacer } from "../../utils/intl"

const About = () => {
  const graphic = useStaticQuery(graphql`
    {
      howItWorks: file(
        relativePath: { eq: "graphic/how-it-works/grafika@2x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 510, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      vocabularyPreviews: file(
        relativePath: { eq: "graphic/vocabulary-previews/grafika@2x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 439, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      practicePreviews: file(
        relativePath: { eq: "graphic/practice-previews/grafika@2x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 444, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Section>
      <Section extend={aboutSectionExtend} narrow>
        <Heading2 id={anchorItems.about}>
          <FormattedMessage id="about.title" />
        </Heading2>
        <FormattedMessage
          id="about.text"
          tagName="p"
          values={{ strong: strongTagReplacer }}
        />
        {/* <Note icon="clock">
          <FormattedMessage id="about.note" values={{ strong: strongTagReplacer }} />
        </Note> */}
      </Section>

      {/* <LazyLoad height={200}> */}
      <DescriptionSection
        title={<FormattedMessage id="about.explain.title" />}
        icon="explain"
        image={{
          image: graphic.howItWorks.childImageSharp.gatsbyImageData,
          alt: "",
        }}
      >
        <FormattedMessage
          id="about.explain.text.paragraph1"
          tagName="p"
          values={{ strong: strongTagReplacer }}
        />
        <FormattedMessage
          id="about.explain.text.paragraph2"
          tagName="p"
          values={{ strong: strongTagReplacer }}
        />
      </DescriptionSection>

      <DescriptionSection
        title={<FormattedMessage id="about.vocabulary.title" />}
        icon="vocabulary"
        image={{
          image: graphic.vocabularyPreviews.childImageSharp.gatsbyImageData,
          alt: "",
        }}
        swapColumns
      >
        <FormattedMessage
          id="about.vocabulary.text.paragraph1"
          tagName="p"
          values={{ strong: strongTagReplacer }}
        />
        <FormattedMessage
          id="about.vocabulary.text.paragraph2"
          tagName="p"
          values={{
            a: text => <a href={`#${anchorItems.patrons}`}>{text}</a>,
            strong: strongTagReplacer,
          }}
        />
      </DescriptionSection>

      <DescriptionSection
        title={<FormattedMessage id="about.practice.title" />}
        icon="test"
        image={{
          image: graphic.practicePreviews.childImageSharp.gatsbyImageData,
          alt: "",
        }}
      >
        <FormattedMessage
          id="about.practice.text.paragraph1"
          tagName="p"
          values={{ strong: strongTagReplacer }}
        />
        <FormattedMessage
          id="about.practice.text.paragraph2"
          tagName="p"
          values={{
            strong: strongTagReplacer,
          }}
        />
      </DescriptionSection>

      <Benefits
        benefits={[
          { id: "benefit1", icon: "begin" },
          { id: "benefit2", icon: "tool" },
          { id: "benefit3", icon: "fun" },
          { id: "benefit4", icon: "statistics" },
        ]}
      />
      <Citations
        citations={[
          { id: "dana", color: "green" },
          { id: "zuzana" },
          { id: "jolana", color: "orange" },
        ]}
      />
      {/* </LazyLoad> */}
    </Section>
  )
}

About.propTypes = {}

export default About
